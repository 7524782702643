// BackgroundVideo.js
import React from "react";
import "./BackgroundVideo.css";
import blancLogoBlack from './assets/logo_new_white.png';

const BackgroundVideo = () => {
  return (
    <div className="video-container">
      <video autoPlay loop muted className="background-video">
        <source src="/video.mp4" type="video/mp4" />
        Tarayıcınız bu videoyu desteklemiyor.
      </video>
      <div className="content">
        {/* Video üstü içerik */}
        <img src={blancLogoBlack} alt="Logo" className="rounded float-start m-4" style={{ maxWidth: '300px' }}/>
      </div>
    </div>
  );
};

export default BackgroundVideo;
