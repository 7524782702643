import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer className="text-black background" >
        <div className="row">
        </div>
      <div className="text-center py-3" style={{ color: '#BEBEBE' }}>
        <small>© 2024 SaloCompany - Her hakkı saklıdır.</small>
      </div>
    </footer>
  );
}

export default Footer;
