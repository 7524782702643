import React from 'react';
import './Contact.css'; // İsteğe bağlı CSS dosyası
import contactImage from '../assets/contact.png'; // İletişim resmi (bu yolu kendi resminizle değiştirin)

const Contact = () => {
  return (
    <div class="contact-container">

        <div class="contact-info">
            <h2 className=''>Düşlerinizi tasarıma taşımaya hazır mısınız?</h2>
            <ul class="contact-info-list">
                <li class="contact-info-item"><strong>Telefon: <a href="tel:+905466017202">+90 (546) 601 72 02</a></strong></li>
                <li class="contact-info-item"><strong>WhatsApp ile <a href='https://wa.me/905466017202'>Mesaj Gönder</a></strong></li>
                <li class="contact-info-item"><strong>E-Posta: <a href='info@blacarch.com'>info@blacarch.com</a></strong></li>
                <li class="contact-info-item"><strong>Adres: <a href='https://maps.app.goo.gl/7qiK5AyNpamD3FLMA'>Valide Atik Mahallesi, Kasımağa Sokak No: 15 Daire: 1 Üsküdar/İstanbul</a></strong></li>
            </ul>
        </div>
        <div class="form-container">
            <form>
                <input class="form-input" type="text" name="name" placeholder="Ad Soyad *" required/>
                <input class="form-input" type="email" name="email" placeholder="E-Posta *" required/>
                <input class="form-input" type="text" name="phone" placeholder="Telefon Numarası *" required/>
                <input class="form-input" type="text" name="company" placeholder="İşletme Adı"/>
                <input class="form-input" type="text" name="position" placeholder="Unvan"/>
                <select class="form-select" name="city">
                    <option value="">Şehir Seçiniz</option>
                    <option value="istanbul">İstanbul</option>
                    <option value="ankara">Ankara</option>
                    <option value="izmir">İzmir</option>
                </select>
                <textarea class="form-textarea" name="note" placeholder="Not" rows="4"></textarea>
                <label class="form-label">
                    <input class="form-checkbox" type="checkbox" name="consent" required/>
                    Kişisel verilerimin işlenmesine ve paylaşılmasına ilişkin Aydınlatma Metni’ni okudum, açık rızam ile onay veriyorum.
                </label>
                <button class="form-button" type="submit">Gönder</button>
            </form>
        </div>
    </div>
  );
};

export default Contact;