import React, { useState, useEffect } from 'react';
import BackgroundVideo from "./BackgroundVideo";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import blancLogoWhite from './assets/BlancArchWhite.png';
import blancLogoBlack from './assets/BlancArchBlack.png';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home'
import Services from './pages/Services';
import About from './pages/About';
import Order from './pages/Order';
import Contact from './pages/Contact';
import ContactForm from './pages/ContactForm';
import Footer from './Footer';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [logo, setLogo] = useState(blancLogoWhite);
  const [icon, setIcon] = useState('custom-toggler-icon');

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const screenWidth = window.innerWidth;
    
    const scrollThreshold = screenWidth <= 768 ? 720 : 1080;
    
    setIsScrolled(scrollTop > scrollThreshold);
    if (scrollTop > scrollThreshold) {
      setLogo(blancLogoBlack);
      setIcon('custom-toggler-icon-black');
    } else {
      setLogo(blancLogoWhite);
      setIcon('custom-toggler-icon');
    }
  };
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div>
        <div>
          <BackgroundVideo />
        </div>
        
        <nav className={`navbar navbar-expand-lg ${isScrolled ? 'scrolled' : ''} fixed-top`}>
          <div className="container-fluid d-flex align-items-center">
            {/* Logo */}
            <div className="d-flex align-items-center me-auto">
              <img src={logo} alt="Logo" className="rounded float-start m-4" style={{ maxWidth: '200px' }} />
            </div>
            {/* Hamburger Menü Butonu */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className={icon}></span>
            </button>
            {/* Menü */}
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">Anasayfa</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services">Hizmetlerimiz</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">Hakkımızda</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/order">Sipariş</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>



        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/order" element={<Order />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/form" element={<ContactForm />} />
          </Routes>

          <div>
          <Footer />
          </div>
      </div>
    </Router>
  );
}

export default App;
