import React from 'react';
import './Home.css'
import adim_tasi from '../assets/adim_tasi.png'
import cati_bahcesi from '../assets/cati_bahcesi.png'
import ev_bahcesi from '../assets/ev_bahcesi.png'
import meydan from '../assets/meydan.png'

function Home() {
  return (
      <div className="home-background">
        <div className="row justify-content-center align-items-center p-2">
            <div className="col-8">
              <img src={meydan} className="img-fluid img-border-4" alt="..."></img>
            </div>
            <div className="col-4 text-center">
              <h3 className="text-right">Yaşayan Mekânlar: Doğanın Dokunuşu</h3>
              <p className="text-right hide-text">Peyzaj, her köşeye hayat veren bir doğa dokunuşudur. İnsanları doğayla buluşturan bu tasarımlar, sakinleştirici yeşil alanlardan enerji dolu sosyal mekânlara kadar her ortamı güzelleştirir. Etkileyici peyzaj çözümleri, hem estetik hem de çevresel dengeyi koruyan yaşam alanları yaratır.</p>
            </div>
          </div>

          <div className="row justify-content-center align-items-center p-2">
            <div className="col-4 text-center">
              <h3 className="text-left">Peyzajın Büyüsü: Doğaya Yakınlaşın</h3>
              <p className="text-left hide-text">Peyzaj tasarımı, yaşam alanlarını daha güzel, sağlıklı ve yaşanabilir kılar. Doğanın zarif güzelliğini şehirle buluşturmak, insanları doğal dünya ile yeniden bağlar. Her projede, yeşil alanların estetik ve işlevsellikteki sihirli gücünü ortaya çıkarıyoruz.</p>
            </div>
            <div className="col-8">
              <img src={adim_tasi} className="img-fluid img-border-1" alt="..."></img>
            </div>
          </div>

          <div className="row mt-3 justify-content-center align-items-center p-2">
            <div className="col-8">
              <img src={cati_bahcesi} className="img-fluid img-border-2" alt="..."></img>
            </div>
            <div className="col-4 text-center">
              <h3 className="text-right">Doğayı Tasarlamak: Peyzajın Gücü</h3>
              <p className="text-right hide-text">Peyzaj, yaşam alanlarını estetik ve işlevsellikle buluştururken doğanın gücünü şehirlerle bütünleştirir. Doğal öğelerle şekillendirilmiş yeşil alanlar, huzuru ve canlılığı bir araya getirerek çevresel sürdürülebilirliği destekler. Güzel tasarlanmış bir peyzaj, sadece göz alıcı bir manzara değil, aynı zamanda insanların doğayla bağ kurduğu, ilham aldığı ve hayat bulduğu dinamik bir mekandır.</p>
            </div>
          </div>

          <div className="row mt-3 mb-3 justify-content-center align-items-center p-2">
            <div className="col-4 text-center">
              <h3 className="text-left">Peyzaj Sanatı: Doğayla Mükemmel Uyum</h3>
              <p className="text-left hide-text">Peyzaj tasarımı, doğanın en saf formlarını estetikle buluşturarak yaşam alanlarını dönüştürür. Yeşil dokular, su öğeleri ve doğal detaylar, dinlendirici ve ilham verici mekânlar yaratır. Her tasarım, doğayla kusursuz bir uyum sağlamak için özenle düşünülmüş bir sanat eseridir.</p>
            </div>
            <div className="col-8">
              <img src={ev_bahcesi} className="img-fluid img-border-3" alt="..."></img>
            </div>
          </div>
      </div>
  );
}

export default Home;
