import React from 'react';
import './Services.css'
import peyzaj_bakim from '../assets/peyzaj_bakim.png'
import peyzaj_uygulama from '../assets/peyzaj_uygulama.png'
import proje_cizim from '../assets/proje_cizim.png'
import sulama from '../assets/sulama.png'
import bitki_tedarik from '../assets/bitki_tedarik.png'
import ServicesCardFirst from '../components/ServicesCardFirst';
import ServicesCardSecond from '../components/ServicesCardSecond';

function Services() {
  const popupContent = {
    title: "Hızlı İletişim",
    description: "Daha fazla bilgi ve özel teklifler için bizimle iletişime geçin.",
    redirectTo: "/contact"
  };

  return (
    <div className="App service-background">
      <div className='container'>
        <ServicesCardFirst
            image={peyzaj_uygulama}
            title="Peyzaj Uygulaması"
            content="Peyzaj uygulama hizmetlerimizle, dış mekanlarınızı estetik ve işlevsellikle dönüştürüyoruz. Uzman ekibimiz, projelerinizi özenle planlayarak bitkilendirme, sert zemin ve özel detaylarla hayata geçirir. Vizyonumuz, doğaya saygılı, sürdürülebilir ve büyüleyici mekanlar yaratmaktır."
            popupContent={popupContent}
            popupImage={peyzaj_uygulama}
        />
        <ServicesCardSecond
            image={peyzaj_bakim}
            title="Peyzaj Bakımı"
            content="Peyzaj bakımı, yeşil alanlarınızın sağlıklı ve estetik kalmasını sağlar. Alanında uzman ekibimiz, bitki budama, çim biçme, gübreleme ve hastalık önleme gibi tüm bakım hizmetlerini sunar. Vizyonumuz, her zaman canlı ve davetkar yaşam alanları oluşturmak ve doğanın dengesini korumaktır."
            popupContent={popupContent}
            popupImage={peyzaj_bakim}
        />
        <ServicesCardFirst
            image={proje_cizim}
            title="Peyzaj Tasarımı"
            content="Her projemizde, doğanın zarif dokusunu modern tasarım anlayışıyla buluşturuyoruz. Peyzaj tasarımı ekibimiz, yaratıcı fikirlerle size özel çözümler üretir. Vizyonumuz, hem estetik hem de işlevsellik açısından benzersiz yaşam alanları oluşturarak doğayla güçlü bir bağ kurmaktır."
            popupContent={popupContent}
            popupImage={proje_cizim}
        />
        <ServicesCardSecond
            image={sulama}
            title="Sulama Sistemleri"
            content="Bitkilerinizin sağlıklı gelişimi için sulama sistemleri büyük önem taşır. Otomatik ve verimli sulama çözümlerimizle su tasarrufu sağlarken bitkilerinizin ihtiyacını en iyi şekilde karşılıyoruz. Vizyonumuz, yenilikçi teknolojilerle sürdürülebilir ve çevre dostu sistemler kurmaktır."
            popupContent={popupContent}
            popupImage={sulama}
        />
        <ServicesCardFirst
            image={bitki_tedarik}
            title="Bitki"
            content="Projeleriniz için en sağlıklı ve kaliteli bitkileri sağlıyoruz. Seçim, tedarik ve nakliye süreçlerinde profesyonel hizmet sunarak projelerinize değer katıyoruz. Vizyonumuz, doğanın zarafetini ve güzelliğini yansıtan bitkilerle projelerinizi canlandırmaktır."
            popupContent={popupContent}
            popupImage={bitki_tedarik}
        />
      </div>
    </div>
  );
}

export default Services;