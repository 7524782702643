import React from 'react';
import './About.css';
import aboutImage from '../assets/logo.png';

const About = () => {
  return (
    <div className="about-container">
      <h1>Biliyoruz ki en değerli yatırım, doğaya ve insana yapılan yatırımdır.</h1>
      <p>Blancarc olarak, peyzaj mimarlığını daha iyi yerlere taşımak ve doğanın sunduğu eşsiz güzellikleri topluma kazandırmak için yola çıktık. Biz, doğayı sadece bir kaynak değil, aynı zamanda ilham kaynağı olarak gören bir ekip olarak, her projede doğanın büyüsünü yansıtmaya ve bu büyüyü insanların yaşamlarına entegre etmeye kararlıyız.</p>
      <p>Peyzaj mimarlığı, doğayla insan arasındaki bağlantıyı güçlendiren bir sanattır. Bizim misyonumuz, bu sanatı en yüksek düzeye çıkarmak ve insanların yaşam alanlarını daha estetik, işlevsel ve sürdürülebilir hale getirmektir. Projelerimizde, çevresel dengeyi korumak, ekosistemleri geliştirmek ve biyoçeşitliliği desteklemek temel prensiplerimizdendir. Her bir tasarımımız, doğal kaynakları koruyarak ve sürdürülebilir malzemeleri kullanarak çevreye duyarlı bir yaklaşım benimsemektedir.</p>
      <p>İnovasyon, bizim için sadece yeni fikirler üretmek değil, aynı zamanda mevcut kaynakları daha etkili bir şekilde kullanmaktır. Doğanın dinamiklerini gözlemleyerek, kullanıcıların ihtiyaçlarını ve beklentilerini dikkate alarak, yaşanabilir alanlar tasarlıyoruz. Her projemiz, doğanın sunduğu potansiyeli en iyi şekilde değerlendirmek için titizlikle hazırlanmakta ve detaylara büyük önem verilmektedir.</p>
      <p>Aynı zamanda, doğa hakkında bilgi ve bilinç yaratma çabamız da büyük bir önem taşıyor. Eğitici projeler ve atölyeler aracılığıyla, doğanın korunması ve gelecek nesillere aktarılması konularında toplumu bilinçlendirmeyi amaçlıyoruz. Amacımız, insanların doğayla olan bağlarını güçlendirirken, çevresel farkındalık oluşturarak sürdürülebilir bir gelecek inşa etmektir.</p>
      <p>Blancarc, peyzaj mimarlığını sadece bir meslek değil, bir yaşam biçimi olarak görmekte ve bu anlayışla geleceği şekillendirmeye çalışmaktadır. Doğa ile uyum içinde, ilham verici ve sürdürülebilir alanlar yaratmak için buradayız. Bizimle birlikte bu yolculuğa çıkmak ve doğanın güzelliklerini keşfetmek için hazır mısınız?</p>

    </div>
  );
};

export default About;
